import React from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const Seo = ({ title, description, image, pathname, keywords, aditionalKeywords, titleSuffix, context:{type, categoryGroup, category, imagePageShortName }={} }) => (
    <StaticQuery
        query={query}
        render={({
            site: {
                siteMetadata: {
                    defaultTitle,
                    defaultDescription,
                    siteUrl,
                    defaultImage,
                    defaultKeywords
                },
            },
            categoryCovers,
            categoryGroupCovers
        }) => {

            const getContextInfo = (...context) => {
                const DESCRIPTION_PREFIX = "Fotografías de ";
                const DESCRIPTION_SUFFIX = " tomadas por Gabriel Jürgens, fotógrafo profesional basado en Buenos Aires, Argentina";
                console.log()
                let data = {};
                if(type) {
                    let node = categoryGroupCovers.edges.filter(cover=>cover.node.fields.data.type === type)[0].node;

                    data.title = (title || defaultTitle) + " | " + node.fields.data.typeName;
                    data.keywords = (keywords || defaultKeywords) + ", " + node.fields.data.typeName;
                    data.image = node.fluid.src;
                    data.description = DESCRIPTION_PREFIX + node.fields.data.typeName + DESCRIPTION_SUFFIX;

                    if(categoryGroup) {
                        let node = categoryGroupCovers.edges.filter(cover=>(
                            cover.node.fields.data.type === type &&
                            cover.node.fields.data.categoryGroup === categoryGroup
                        ))[0].node;
                        
                        if(node.fields.data.categoryGroup !== "hidden") {
                            data.title += " | " + node.fields.data.categoryGroupName;
                            data.keywords += ", " + node.fields.data.categoryGroupName;
                            data.description = DESCRIPTION_PREFIX + node.fields.data.categoryGroupName + DESCRIPTION_SUFFIX;
                        }
                        data.image = node.fluid.src;

                        if(category) {
                            let node = categoryCovers.edges.filter(cover=>(
                                cover.node.fields.data.type === type &&
                                cover.node.fields.data.categoryGroup === categoryGroup &&
                                cover.node.fields.data.category === category
                            ))[0].node;
                            
                            
                            data.title += " | " + node.fields.data.categoryName;
                            data.keywords += ", " + node.fields.data.categoryName;
                            data.image = node.fluid.src;
                            data.description = DESCRIPTION_PREFIX + node.fields.data.categoryName + DESCRIPTION_SUFFIX;
                            
                            if(imagePageShortName) {
                                data.title += " | " + imagePageShortName;
                                data.description =  imagePageShortName + ". Fotografía tomada por el fotógrafo profesional Gabriel Jürgens, basado en Buenos Aires, Argentina.";
                            } else return data;
                        } else return data;
                    } else return data;
                } else return data;
                return data;
            }

            const contextInfo = getContextInfo();

            let seo = {
                title: (title || contextInfo.title || defaultTitle) + (titleSuffix ? titleSuffix : ""),
                description: description|| contextInfo.description || defaultDescription,
                image: `${siteUrl}${image || contextInfo.image || defaultImage}`,
                url: `${siteUrl}${pathname || "/"}`,
                keywords: keywords || contextInfo.keywords || defaultKeywords,
                aditionalKeywords: aditionalKeywords,
            }

            return (
                <>
                    <Helmet title={seo.title}>
                        <meta name="description" content={seo.description} />
                        <meta name="image" content={seo.image} />
                        {seo.url && <meta property="og:url" content={seo.url} />}
                        {seo.title && <meta property="og:title" content={seo.title} />}
                        {seo.description && (
                            <meta property="og:description" content={seo.description} />
                        )}
                        {seo.image && <meta property="og:image" content={seo.image} />}
                        {seo.keywords && <meta name="keywords" content={seo.keywords + (seo.aditionalKeywords ? ", " + seo.aditionalKeywords : "")} />}
                        <link rel="canonical" href={seo.url} />
                    </Helmet>
                </>
            )
        }}
    />
)

export default Seo

Seo.defaultProps = {
    title: null,
    description: null,
    image: null,
    pathname: null,
    keywords: null
}

const query = graphql`
fragment Covers on ImageSharp {
    fluid {
      src
    }
    fields {
      data {
        type
        typeName
        categoryGroup
        categoryGroupName
        category
        categoryName
      }
    }
  }
  
  query Seo {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        defaultKeywords: keywords
      }
    }
    categoryGroupCovers: allImageSharp(filter: {fields: {data: {isCategoryGroupCover: {eq: true}}}}) {
      edges {
        node {
          ...Covers
        }
      }
    }
    categoryCovers: allImageSharp(filter: {fields: {data: {isCategoryCover: {eq: true}}}}) {
      edges {
        node {
          ...Covers
        }
      }
    }
  }
  `