import React from "react";
import Layout from '../../components/Layout';
import { Section } from '../../components/Section';
import { CategoryGrid } from '../../components/CategoryGrid';
import { graphql } from 'gatsby';
import Seo from "../../components/Seo";

class CategoryGroup extends React.Component {
    constructor(props) {
        super();
        //console.log(JSON.stringify(props.data,null,4));
        this.data = props.data.allCategories.edges.map((category) => {
            let item = category.node.data;
            item.cover = {};
            for(let i = 0; i < props.data.covers.edges.length; i++) {
                const currentItem = props.data.covers.edges[i].node;
                if(currentItem.fields.data.type === category.node.type && currentItem.fields.data.categoryGroup === category.node.categoryGroup && currentItem.fields.data.category === category.node.category) {
                    item.cover.original = currentItem.original;
                    item.cover.fluid = currentItem.fluid;
                }
            }
            return item;
        });
      }
  
    formatGroupCategoryName(group) {
        const parts = group.split("_");
        //let name = "";
        for(let i = 0; i < parts.length; i++) {
            let chars = parts[i].split("");
            chars[0] = chars[0].toUpperCase();
            parts[i] = chars.join("");
        }

        return parts.join(" ");

    }

    render() {


        let crumbs = [];

       crumbs.push([this.props.pageContext.typeSlug,this.props.pageContext.typeName]);
       crumbs.push(this.props.pageContext.categoryGroupName);


        return (
            <Layout location={this.props.location}>
                <Seo pathname={this.props.path} context={{type:this.props.pageContext.type, categoryGroup: this.props.pageContext.categoryGroup}} />
                <div style={{paddingTop:"50px"}}>
                    <Section crumbs={crumbs} />
                    <CategoryGrid  data={this.data}/>
                </div>
            </Layout>
        )
    }
}

export default CategoryGroup;



export const pageQuery = graphql`

query GroupAutoPage($type: String, $categoryGroup: String) {
  covers: allImageSharp(filter: {fields: {data: {sourceInstanceName: {eq: "pictures"}, categoryGroup: {eq: $categoryGroup}, type: {eq: $type}, isCategoryCover: {eq: true}}}}, sort: {fields: [fields___data___category], order: ASC}) {
    edges {
      node {
        ...CategoryGridCovers
      }
    }
  }
  allCategories(sort: {fields: [data___order], order: ASC}, filter: {categoryGroup: {eq: $categoryGroup}, type: {eq: $type}}) {
    edges {
      node {
        id
        category
        categoryGroup
        type
        data {
            slug
            name
            order
        }
      }
    }
  }
}

`;


/*
export default function Component(props) {
  //return props.data.fields.sku + props.params.name // highlight-line
  return JSON.stringify(props.params,null,4) // highlight-line
}
*/