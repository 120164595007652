import React from 'react';
import styles from './CategoryGrid.module.scss';
import Link from 'gatsby-link'
import { graphql } from 'gatsby'

export class CategoryGrid extends React.Component {

    static DEFAULT_FRAME_BORDER = 8;
    static DEFAULT_EXPECTED_ROW_HEIGHT = 400;

    getFrameBorder = () => {
        return this.props.frameBorder !== undefined ? this.props.frameBorder : CategoryGrid.DEFAULT_FRAME_BORDER;
    };

    getExpectedRowHeight = () => {
        return this.props.rowHeight !== undefined ? this.props.rowHeight - this.getFrameBorder() * 2 : CategoryGrid.DEFAULT_EXPECTED_ROW_HEIGHT - this.getFrameBorder() * 2
    };

    getAdjustedRetinaImagesSrcSet = (originalSrcSet, pixelDensity=1) => {
        const regex = /^(\/.+.(?:webp|jpg)) ([0-9]+)w/gm;
                
        let matches;
        let modifiedParts = [];
        while((matches = regex.exec(originalSrcSet)) !== null) {
            modifiedParts.push(`${matches[1]} ${matches[2] * pixelDensity}w`);
        }
        return modifiedParts.join(",\n")
    }

    componentDidMount() {
        const images = document.querySelectorAll(`.${styles.imageFrameContainer}`);

        const preloadImage = (imageContainer) => {
            let sourceWebpRetina = imageContainer.childNodes[1].childNodes[0];
            let sourceJpegRetina = imageContainer.childNodes[1].childNodes[1];
            let sourceWebpNormal = imageContainer.childNodes[1].childNodes[2];
            let sourceJpegNormal = imageContainer.childNodes[1].childNodes[3];
            let image  = imageContainer.childNodes[1].childNodes[4];
            sourceWebpRetina.srcset = sourceWebpRetina.dataset.srcset;
            sourceJpegRetina.srcset = sourceJpegRetina.dataset.srcset;
            sourceWebpNormal.srcset = sourceWebpNormal.dataset.srcset;
            sourceJpegNormal.srcset = sourceJpegNormal.dataset.srcset;
            image.src = image.dataset.src;
            image.style.display = "block";
        };

        if(typeof IntersectionObserver !== "undefined") {
            setTimeout(() => {
                const config = {
                    // If the image gets within 50px in the Y axis, start the download.
                    rootMargin: '50% 0%',
                    threshold: 0.01
                };

                const onIntersection = (entries) => {
                    entries.forEach((entry) => {
                        if (entry.intersectionRatio > 0) {
                            observer.unobserve(entry.target);
                            preloadImage(entry.target);
                        }
                    });
                };

                let observer = new IntersectionObserver(onIntersection, config);

                images.forEach(image => {
                    observer.observe(image);
                });
            },1);
        } else {
            images.forEach(image => {
                preloadImage(image);
            });
        }
    }

    render() {
        let imageIndex=0;
        return (
            <section className={styles.photoGrid}>
                {this.props.data.map((image) => {
                    imageIndex++;
                    const imageAlt = image.name;
                    return (
                        <Link to={image.slug} className={styles.imageFrameContainer} key={`picture_${imageIndex}`} style={
                            {
                                width: `${(image.cover.original.width*this.getExpectedRowHeight()/image.cover.original.height)}px`,
                                flexGrow:image.cover.original.width*this.getExpectedRowHeight()/image.cover.original.height,
                                borderWidth: this.getFrameBorder(),
                                backgroundImage: `url(${image.cover.fluid.base64})`
                            }
                        }>
                            <i className={styles.expander} style={{paddingBottom: `${image.cover.original.height/image.cover.original.width*100}%`}} />
                            <picture>
                                <source media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)" data-srcset={this.getAdjustedRetinaImagesSrcSet(image.cover.fluid.srcSetWebp, 2)} sizes={image.cover.fluid.sizes} type={"image/webp"}/>
                                <source media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)" data-srcset={this.getAdjustedRetinaImagesSrcSet(image.cover.fluid.srcSet, 2)} sizes={image.cover.fluid.sizes} type={"image/jpeg"}/>
                                
                                <source data-srcset={image.cover.fluid.srcSetWebp} sizes={image.cover.fluid.sizes} type={"image/webp"}/>
                                <source data-srcset={image.cover.fluid.srcSet} sizes={image.cover.fluid.sizes} type={"image/jpeg"}/>

                                <img className={styles.image} data-src={image.cover.fluid.src} sizes={image.cover.fluid.sizes} alt={imageAlt}/>
                            </picture>
                            <div className={image.cover.fluid.aspectRatio >= 1 ? styles.categoryTextHorizontal : styles.categoryTextVertical}>{image.name}{/*<span className={styles.more}>ver&nbsp;+</span>*/}</div>
                        </Link>
                    )
                })}
            </section>
        );
    }

}

export const query = graphql`
fragment CategoryGridCovers on ImageSharp {

  fields {
    data {
      type
      category
      categoryGroup
      isCategoryCover
    }
  }
  fluid(quality: 80) {
    base64
    aspectRatio
    src
    srcSet
    srcSetWebp
    sizes
    originalImg
    originalName
  }
  original {
    width
    height
  }
}
`;