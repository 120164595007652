import React from 'react';
import styles from './Section.module.scss';
import Link from 'gatsby-link';

const formatCrumb = text => text.replace(/_/g," ").replace(/(^|\s)\S/g, l => l.toUpperCase());

export const Section = (props) => {
    let content = [];
    let keyIndex = 0;
    if(props.crumbs instanceof Array && props.crumbs.length > 0) {
        content.push([<Link to={"/"} key={keyIndex++}>Inicio</Link>]);
        content = props.crumbs.reduce((prev,crumb) => {
            let crumbs = [].concat(prev);
            crumbs.push(<span key={keyIndex++}> > </span>);
            if(crumb instanceof Array && crumb.length === 2) {
                crumbs.push(<Link to={crumb[0]} key={keyIndex++}>{formatCrumb(crumb[1])}</Link>)
            } else {
                crumbs.push(<span key={keyIndex++}>{formatCrumb(crumb)}</span>);
            }

            return crumbs;
        },content);
    } else {
        content.push(<span key={keyIndex++}>{props.title}</span>);
    }
    let section;
    if(props.useH2 === true) {
        section = <h2 className={styles.container} key={keyIndex++}>{content}</h2>;
    } else {
        section = <h1 className={styles.container} key={keyIndex++}>{content}</h1>;
    }
    return section;
}